@import '~@/assets/scss/main.scss';

#top-navigation {
    height: 5rem;
    padding-left: 2rem;
    background-color: white;
    @include material-shadow($z-depth: 3, $color: $soft-shadow);

    #page-title {
        font-size: 1.75rem;
        font-weight: 800;
    }

    #breadcrumb {
        font-size: 0.85rem;
        @extend .text-emphasis;
        font-weight: 400;

        .active {
            font-weight: bold;
        }
    }
}
