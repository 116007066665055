@import '~@/assets/scss/main.scss';

body {
    background-color: $background-white;
}

#page-content {
    height: 100vh;
    overflow-y: scroll;
}

#sidebar-col {
    z-index: 1;
}
