@import '~@/assets/scss/main.scss';

p {
    font-size: 0.85rem;
}

.btn.btn-link {
    font-size: 0.8rem;
    color: white;
    font-weight: 600;
}
