@import '~@/assets/scss/main.scss';

.list-group {
    text-align: left;
}

.list-group-item-transparent {
    @extend .text-emphasis;
    font-size: 0.8rem;
    background: transparent;
    border: none;
    transition: all 0.5s ease-out;
    color: white;

    .icon-background {
        color: white;
    }

    .icon-top {
        color: $blue;
    }

    &:hover,
    &.router-link-active {
        background-color: darken($blue, 10%);
        color: white;

        .icon-top {
            color: darken($blue, 10%);
        }
    }
}
