@import '~@/assets/scss/main.scss';

#sidebar-wrapper {
    width: 20rem;
    color: white;
    background: linear-gradient(-45deg, $purple, $blue);
    @include material-shadow($z-depth: 3, $strength: 2);

    #sidebar-heading {
        margin-top: 0.5rem;
        padding: 0.875rem 1.25rem;
        text-align: center;

        #sidebar-heading-divider {
            width: 100%;
            background-color: white;
            height: 0.2rem;
        }
    }

    #app-title {
        font-size: 0.65rem;
    }

    #sidebar-logo {
        height: 3rem;
        margin-bottom: 1rem;
    }
}
